<template>
  <div>
    <div class="d-flex jc-center">
      <dv-decoration-10 style="width: 33.3%; height: 0.0625rem" />
      <div class="d-flex jc-center">
        <dv-decoration-8 :color="['#568aea', '#000000']"
                         style="width: 2.5rem; height: 0.625rem" />
        <div class="title">
          <span class="title-text">
            <DropDownList :dataList="prolist"
                          labelProperty="Name"
                          @change="onDpChange($event)"
                          :id="pro.Id"></DropDownList>
          </span>
          <dv-decoration-6 class="title-bototm"
                           :reverse="true"
                           :color="['#50e3c2', '#67a1e5']"
                           style="width: 3.125rem; height: 0.1rem" />
        </div>
        <dv-decoration-8 :reverse="true"
                         :color="['#568aea', '#000000']"
                         style="width: 2.5rem; height: 0.625rem" />
      </div>
      <dv-decoration-10 style="width: 33.3%; height: 0.0625rem; transform: rotateY(180deg)" />
    </div>
    <div class="d-flex jc-between px-2">
      <div class="d-flex"
           style="width: 40%">
        <div v-if="name=='project'"
             class="react-right ml-4 bg-color-blue"
             style="width: 6.25rem; text-align: left;">
          <span class="react-before"
                style="background-color: #1a5cd7 !important;"></span>
          <span class="text fw-b bg-color-blue">首页</span>
        </div>

        <div v-else
             class="react-right ml-4 "
             style="width: 6.25rem;text-align: left;background-color: #0f1325;">
          <span class="react-before"></span>
          <span class="text fw-b">
            <a class="react-right ml-3 menu"
               href="/"><span class="colorBlue">首页</span></a>
          </span>
        </div>
        <template v-for="item in rightmenulist">

          <template v-if="item.child && item.child.length>0">
            <a-dropdown v-if="name.indexOf(item.key) ==0"
                        :trigger="['click']"
                        :key="item.key"
                        href="javascript:;">
              <a class="react-right ml-3 bg-color-blue"
                 style="color: #d3d6dd;"
                 @click="e => e.preventDefault()">
                <span class="text fw-b"> {{ item.name }} <a-icon type="down" /></span>
              </a>
              <a-menu slot="overlay">
                <a-menu-item v-for="menuitem in item.child"
                             :key="menuitem.key">
                  <a v-if="name === menuitem.key"
                     href="javascript:;"
                     class="react-right ml-3 bg-color-blue"
                     style="color: #d3d6dd;">{{ menuitem.name }}</a>
                  <a v-else
                     :href="menuitem.href">{{ menuitem.name }}</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>

            <a-dropdown v-else
                        :trigger="['click']"
                        :key="item.key"
                        href="javascript:;">
              <a class="react-right ml-3 menu"
                 @click="e => e.preventDefault()">
                <span class="text colorBlue">{{ item.name }} <a-icon type="down" /></span>
              </a>
              <a-menu slot="overlay">
                <a-menu-item v-for="menuitem in item.child"
                             :key="menuitem.key">
                  <a v-if="name === menuitem.key"
                     href="javascript:;"
                     class="react-right ml-3 bg-color-blue"
                     style="color: #d3d6dd;">{{ menuitem.name }}</a>
                  <a v-else
                     :href="menuitem.href">{{ menuitem.name }}</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
          <template v-else>
            <span v-if="name == item.key"
                  :key="item.key"
                  class="react-right ml-3 bg-color-blue"
                  href="javascript:;">
              <span class="text fw-b">{{ item.name }}</span></span>
            <a v-else
               :key="item.key"
               class="react-right ml-3 menu"
               :href="item.href">
              <span class="text colorBlue">{{ item.name }}</span></a>
          </template>
        </template>
      </div>
      <div style="width: 40%"
           class="d-flex">
        <template v-for="item in leftmenulist">
          <template v-if="item.child && item.child.length>0">
            <a-dropdown v-if="name.indexOf(item.key) ==0"
                        :key="item.key"
                        href="javascript:;">
              <a class="react-left ml-3 bg-color-blue"
                 style="color: #d3d6dd;"
                 @click="e => e.preventDefault()">
                <span class="text fw-b"> {{ item.name }} <a-icon type="down" /></span>
              </a>
              <a-menu slot="overlay">
                <a-menu-item v-for="menuitem in item.child"
                             :key="menuitem.key">
                  <a v-if="name === menuitem.key"
                     href="javascript:;"
                     class="react-left ml-3 bg-color-blue"
                     style="color: #d3d6dd;">{{ menuitem.name }}</a>
                  <a v-else
                     :href="menuitem.href">{{ menuitem.name }}</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>

            <a-dropdown v-else
                        :key="item.key"
                        href="javascript:;">
              <a class="react-left ml-3 menu"
                 @click="e => e.preventDefault()">
                <span class="text colorBlue">{{ item.name }} <a-icon type="down" /></span>
              </a>
              <a-menu slot="overlay">
                <a-menu-item v-for="menuitem in item.child"
                             :key="menuitem.key">
                  <a v-if="name === menuitem.key"
                     href="javascript:;"
                     class="react-right ml-3 bg-color-blue"
                     style="color: #d3d6dd;">{{ menuitem.name }}</a>
                  <a v-else
                     :href="menuitem.href">{{ menuitem.name }}</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
          <template v-else>
            <span v-if="name == item.key"
                  :key="item.key"
                  class="react-left mr-3 bg-color-blue">
              <span class="text fw-b">{{ item.name }}</span></span>
            <a v-else
               :key="item.key"
               class="react-left mr-3 menu"
               :href="item.href"><span class="text colorBlue">{{ item.name }}</span></a>
          </template>
        </template>
        <!-- <div>
        <span v-if="name == 'tower'" class="react-left mr-3 bg-color-blue" ></span>
        <span v-else class="react-left mr-3 menu" ></span>
      </div> -->
        <div class="react-left mr-4"
             style="width: 6.25rem; background-color: #0f1325; text-align: right">
          <span class="react-after"></span>
          <span class="text">{{ dateDay }}
            <a-divider type="vertical" />
            <a-dropdown>
              <span class="action ant-dropdown-link user-dropdown-menu">
                <a-avatar size="small"
                          icon="user" />
                <!--     <span>{{ op().UserName }}</span> -->
              </span>
              <a-menu slot="overlay"
                      class="user-dropdown-menu-wrapper">
                <a-menu-item key="1">
                  <a href="/project">
                    <a-icon type="home" />
                    <span>项目首页</span>
                  </a>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item key="3">
                  <a href="javascript:;"
                     @click="handleLogout()">
                    <a-icon type="logout" />
                    <span>退出登录</span>
                  </a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatTime } from "@/utils/index.js";
import OperatorCache from "@/utils/cache/OperatorCache";
import TokenCache from "@/utils/cache/TokenCache";
import ProInfoCache from "@/utils/cache/ProInfoCache"
import DropDownList from "@/components/dropdownList.vue"
const rightmenulist = [
  {
    key: "person", href: "/realname", name: "人员管理", child: [
      { key: "person-realname", href: "/realname", name: "实名制" },
      // { key: "person-education", href: "/notopen", name: "安全教育" },
      { key: "postion", href: "/postion", name: "人员定位" },
    ]
  },
  {
    key: "evn", href: "/evn", name: "环境监测", child: [
      { key: "evn-dust", href: "/evn", name: "扬尘" },
      //{key: "evn-gas", href: "/notopen", name: "废气监测"},
      //{key: "evn-woter", href: "/notopen", name: "废水监测"},
      //{key: "evn-solid", href: "/notopen", name: "固体垃圾"},
    ]
  },
  {
    key: "video", href: "/video", name: "监控", child: [
      { key: "video-video", href: "/video", name: "视频监控" },
      // { key: "video-aerial-photo", href: "/notopen", name: "航拍全景"},
      { key: "video-monitor", href: "/monitor", name: "全景监控" },
    ]
  },
];
const leftmenulist = [

  {
    key: "device", href: "/", name: "安全设备", child: [
      { key: "device-tower", href: "/tower", name: "塔机" },
      { key: "device-elevator", href: "/elevator", name: "升降机" },
      { key: "device-unloading", href: "/notopen", name: "料台" }]
  },
  {
    key: "quality", href: "/quality", name: "质量管理"
  },
  {
    key: "safety", href: "/safety", name: "安全管理",
  }
];
export default {
  props: {
    name: {
      type: String,
      default: "project",
    },
  },
  created () {
    this.prolist = ProInfoCache.getPros();
    this.pro = ProInfoCache.getProInfo()
  },
  data () {
    return {
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      prolist: [],
      pro: {},
      rightmenulist,
      leftmenulist,
    };
  },
  components: { DropDownList },
  mounted () {
    this.timeFn();
  },
  methods: {
    onDpChange (e) {
      OperatorCache.loadChooseProInfo(e.value.Id)
      this.pro = e.value
      this.$emit("change", e.value)
    },
    handleLogout () {
      this.$confirm({
        title: "提示",
        content: "真的要注销登录吗 ?",
        onOk () {
          TokenCache.deleteToken();
          OperatorCache.clear();

          if (['10.101.15.140'].includes(document.domain))
            window.location.href = 'http://10.101.15.141/mh'
          else
            location.reload()

        },
      });
    },
    timeFn () {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
  },
};
</script>
<style scoped="scss">
@import "/assets/scss/index.scss";
.menu {
  background-color: #0f1325;
}
.ant-dropdown {
  left: calc(+100);
  left: -moz-calc(+100);
  left: -webkit-calc(+100);
}
.ant-dropdown-menu {
  background-color: #0f1325;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: blue;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  color: blue;
}
.ant-dropdown-menu-item-active {
  background: blue;
  color: #0f1325;
}
.ant-dropdown-menu-item-active > a {
  background: blue;
  color: #d3d6dd;
}
</style>